<template>
  <div class="add__post--wrapper">
    <Loader v-if="isPostLoading" />
    <div v-else class="add__post--inner">
      <ValidationObserver v-slot="{ invalid }" name="add-post-observer">
        <form @submit.prevent="submitForm">
          <div class="add__post--header">
            <div
              role="button"
              @click="handlePostActions('goBack')"
              data-test-id="discussion-post-submit-btn"
              class="back-btn"
            >
              <LxpLoader class="draft-spinner" v-if="isDraftLoading" />
              <img
                :src="require(`@/assets/images/vle/forums/back-icon.svg`)"
                alt="back"
                width="24"
                height="24"
                class="back-image"
                v-else
              />
              <span class="back__btn--text">
                {{ $t("general.back") }}
              </span>
            </div>
            <div class="action-panel">
              <div
                class="form-check d-flex align-items-center"
                :class="{ active: isAnonymous }"
                v-if="!isPostEditable"
              >
                <label class="body-text c-pointer" for="anonymouslyCheck">
                  <input
                    type="checkbox"
                    :checked="isAnonymous"
                    v-model="isAnonymous"
                    class="form__check--input c-pointer"
                    id="anonymouslyCheck"
                  />
                  <img :src="checkboxChecked" alt="checked" class="checkmark" />
                  {{ $t("discussions.new_post.anonymous.label") }}
                </label>
              </div>
              <div ref="toxic__language-tooltip">
                <LxpButton
                  variant="accent"
                  :disabled="
                    invalid ||
                      isTextLimitExceeded ||
                      !topicId ||
                      newBlockCounter > textLimits[currentField]
                  "
                  type="submit"
                  v-if="!isPostEditable"
                  name="publish"
                  @click="setSubmitType('publish')"
                  :class="{
                    'try-again': tryAgain && !isPostEditable,
                    'loading-btn': isLoading
                  }"
                >
                  {{
                    !isLoading
                      ? tryAgain && !isPostEditable
                        ? $t("general.try_again")
                        : $t("vle.publish")
                      : ""
                  }}
                  <LxpLoader
                    :class="{ 'yellow-spinner': tryAgain }"
                    v-if="isLoading"
                  />
                </LxpButton>
                <LxpButton
                  variant="accent"
                  :disabled="disableUpdateBtn"
                  type="submit"
                  v-if="isPostEditable"
                  name="update"
                  @click="setSubmitType('update')"
                  :class="{
                    'try-again': tryAgain && isPostEditable,
                    'loading-btn': isLoading
                  }"
                >
                  {{
                    !isLoading
                      ? tryAgain && isPostEditable
                        ? $t("general.try_again")
                        : $t("general.update")
                      : ""
                  }}
                  <LxpLoader
                    :class="{ 'yellow-spinner': tryAgain }"
                    v-if="isLoading"
                  />
                </LxpButton>
                <b-tooltip
                  :target="() => $refs['toxic__language-tooltip']"
                  triggers="hover focus"
                  placement="bottom"
                  v-if="
                    !tryAgain &&
                      !isTitleToxic &&
                      !isBodyToxic &&
                      !newBlockArray.some(item => item.isToxic)
                  "
                >
                  <div class="cd__tooltip--inner">
                    {{
                      invalid ||
                      isTextLimitExceeded ||
                      !topicId ||
                      newBlockCounter > textLimits[currentField]
                        ? $t("discussions.post.insert_content_tooltip")
                        : $t("discussions.post.good_content_tooltip")
                    }}
                  </div>
                </b-tooltip>
              </div>
              <img
                :src="require(`@/assets/images/vle/forums/delete-blue.svg`)"
                alt="delete"
                width="40"
                height="40"
                role="button"
                class="delete-btn"
                v-if="isPostEditable || getSelectedDiscussionPost.is_draft"
                @click="showDeletePostModal"
              />
            </div>
          </div>
          <div class="addpost-content">
            <div class="scrollbar-wrapper">
              <div class="addpost__content--inner">
                <div class="addpost__content--header">
                  <div
                    class="select-category"
                    id="select-category"
                    :aria-label="$t('discussions.post.select_subcategory')"
                    @click="toggleDropdown"
                  >
                    <div
                      class="selector"
                      :class="{ 'selector-active': topicId }"
                      @mouseover="isDropdownHovered = true"
                      @mouseout="isDropdownHovered = false"
                    >
                      <span class="label">{{ dropdownValue }}*</span>
                      <img
                        :src="
                          isDropdownHovered && !topicId
                            ? chevronHover
                            : topicId
                            ? chevronSelected
                            : chevronDefault
                        "
                        alt="arrow"
                        :style="{
                          transform: isDropdownOpen ? 'rotate(180deg)' : 'none'
                        }"
                      />
                    </div>
                    <div class="select-item" v-if="isDropdownOpen">
                      <ul class="select__item--inner">
                        <template v-for="(item, index) in showDiscussionTopics">
                          <li
                            class="clickable-category"
                            :class="{
                              current: item.id === topicId
                            }"
                            v-if="item.categories.length === 0"
                            :key="item.id"
                            @click="selectDropdownItem(item)"
                          >
                            <span>{{ item.name }}</span>
                            <span v-if="item.id === topicId">
                              <img :src="itemSelected" alt="tick" />
                            </span>
                          </li>
                          <li
                            v-else
                            :key="`subcat-${index}`"
                            class="category-list"
                          >
                            <ul>
                              <li :title="item.name" class="parent-category">
                                {{ item.name }}
                              </li>
                              <li
                                class="clickable-category"
                                :class="{
                                  current: subItem.id === topicId
                                }"
                                v-for="subItem in item.categories"
                                :value="subItem.id"
                                :key="subItem.id"
                                @click="selectDropdownItem(subItem)"
                              >
                                <span>{{ subItem.name }}</span>
                                <span v-if="subItem.id === topicId">
                                  <img :src="itemSelected" alt="tick" />
                                </span>
                              </li>
                            </ul>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                  <div class="post-user">
                    <div
                      class="user-image"
                      :class="{
                        'user-bg': hasProfileImage
                      }"
                    >
                      <img :src="userImageUrl" alt="avatar" />
                    </div>
                    <span>{{ displayName }}</span>
                  </div>
                </div>
                <!-- reference URL ( https://vee-validate.logaretm.com/v2/guide/components/validation-observer.html#rendering ) -->
                <ValidationProvider name="Title" rules="required">
                  <div :class="['form-group', { 'toxic-input': isTitleToxic }]">
                    <input
                      type="text"
                      class="form-control form__control--edit input-edit"
                      v-model="title"
                      aria-label="Add title text"
                      :placeholder="
                        $t('discussions.new_post.title.placeholder')
                      "
                      @input="updateTextCount('title')"
                      @focus="handleFocus('title')"
                      @blur="handleBlur('title')"
                      ref="titleInput"
                    />
                  </div>
                </ValidationProvider>
                <ValidationProvider name="Body" rules="required">
                  <div class="form-group">
                    <text-format
                      v-model="body"
                      aria-label="Add body text"
                      :placeholder="$t('discussions.new_post.body.placeholder')"
                      @input="updateTextCount('body')"
                      @focus="handleFocus('body')"
                      @inputHTML="inputHTML('body', $event)"
                      ref="bodyInput"
                      :initial-content="
                        getSelectedDiscussionPost.is_draft || isPostEditable
                          ? getSelectedDiscussionPost.body
                          : ''
                      "
                      :class="{ 'toxic-input': isBodyToxic }"
                    >
                    </text-format>
                  </div>
                </ValidationProvider>
                <div v-for="(block, index) in newBlockArray" :key="block.id">
                  <div class="form-group" v-if="block.type === 'text'">
                    <text-format
                      v-model="block.briefDescription"
                      aria-label="Add description text"
                      :placeholder="
                        $t('discussions.new_post.description.placeholder')
                      "
                      @input="updateTextCount('briefDescription')"
                      @focus="handleFocus('briefDescription', index)"
                      @inputHTML="
                        inputHTML(
                          'briefDescription',
                          $event,
                          index,
                          block.isUpdatingContent,
                          block.id
                        )
                      "
                      :ref="'briefDescriptionInput-' + index"
                      :initial-content="getContentById(block.id)"
                      :class="{ 'toxic-input': block.isToxic }"
                    >
                    </text-format>
                  </div>
                  <ValidationProvider
                    name="Alt"
                    rules="max:50"
                    v-slot="{ errors }"
                    class="image__block--container"
                    v-if="block.type === 'image'"
                  >
                    <div
                      class="image-block image-loading"
                      v-if="!block.isLoaded && !block.errorMessage"
                    >
                      <img :src="imageUpload" alt="Image Upload" />
                      <LxpLoader variant="primary" />
                    </div>
                    <div
                      class="image-block uploaded-image"
                      v-show="block.isLoaded"
                    >
                      <div
                        class="remove-image"
                        @click="removeImageBlock(index, block.id)"
                      >
                        <img
                          :src="
                            block.showSuccessCheck ? successImage : removeImage
                          "
                          alt="Remove Image"
                        />
                      </div>
                      <img
                        :src="block.newImage"
                        :alt="block.alt_tag ?? 'Post Image'"
                        @load="loadImage(index)"
                      />
                      <input
                        class="image-alt"
                        type="text"
                        v-model="block.alt_tag"
                        :placeholder="
                          $t('discussions.new_post.image.placeholder')
                        "
                      />
                    </div>
                    <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                    <div class="image-error" v-if="block.errorMessage">
                      <img :src="imageError" alt="Image Error" />
                      <div class="image__error--text">
                        <span>{{ block.errorMessage }}</span>
                        <span class="image-retry" @click="showImageBlock">
                          {{ $t("general.retry") }}
                        </span>
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="add-block">
                  <div
                    class="add-icon icon-style c-pointer"
                    @click="toggleAddBlock"
                  >
                    <img
                      :src="addBlockIcon"
                      :style="{
                        transform: optionVisible
                          ? 'rotate(135deg)'
                          : 'rotate(0deg)'
                      }"
                      alt="Add Block"
                    />
                  </div>
                  <ul class="block-options" :class="{ active: optionVisible }">
                    <li class="icon-style c-pointer" @click="showImageBlock">
                      <img :src="addImage" alt="Add Image" />
                    </li>
                    <li
                      class="icon-style c-pointer"
                      @click="showDescriptionBlock"
                    >
                      <img :src="addText" alt="Add Text" />
                    </li>
                  </ul>
                </div>
                <div
                  class="counter"
                  :class="{
                    'counter-error':
                      newBlockCounter &&
                      newBlockCounter > textLimits[currentField]
                  }"
                  v-if="
                    newBlockCounter &&
                      newBlockCounter > 0 &&
                      currentField === 'briefDescription' &&
                      !hideCounter
                  "
                >
                  <span
                    class="error-text"
                    v-if="
                      newBlockCounter &&
                        newBlockCounter > textLimits[currentField]
                    "
                  >
                    {{ $t("discussions.new_post.error.text_count") }}
                  </span>
                  <span class="counter-number">
                    {{ newBlockCounter }} /
                    {{ textLimits[currentField] }}
                  </span>
                </div>
                <div
                  class="counter"
                  :class="{ 'counter-error': isTextLimitExceeded }"
                  v-if="showCounter && currentField !== 'briefDescription'"
                >
                  <span class="error-text" v-if="isTextLimitExceeded">
                    {{ $t("discussions.new_post.error.text_count") }}
                  </span>
                  <span class="counter-number">
                    {{ textCounts[currentField] }} /
                    {{ textLimits[currentField] }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <input
            type="file"
            id="post-image"
            ref="postImage"
            class="d-none"
            accept=".png,.jpg,.jpeg"
            @change="onUploadChange"
          />
        </form>
      </ValidationObserver>
    </div>
    <Modals
      @delete-post="deletePost"
      @draft-post="handlePostActions('draftPost')"
    />
    <Toast
      id="toxic-language-toast"
      :imgPath="require('@/assets/images/vle/toxic-content.svg')"
      :title="$t('discussions.new_post.toast.toxic.title')"
      :description="$t('discussions.new_post.toast.toxic.description')"
      :autoHide="false"
    ></Toast>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import discussionViewSelectorMixin from "@/core/mixins/discussionViewSelector.js";
import { LxpButton, LxpLoader } from "didactica";
import axios from "axios";
import TextFormat from "@/components/TextFormat.vue";
import Modals from "./Modals.vue";
import DISCUSSION_FORUM_MODAL_IDS from "./constants";
import Toast from "@/components/SelfPaced/Toast.vue";
import Loader from "@/components/WrapperComponents/Loader.vue";
export default {
  mixins: [discussionViewSelectorMixin],
  components: {
    LxpButton,
    "text-format": TextFormat,
    Modals,
    Toast,
    LxpLoader,
    Loader
  },
  props: {
    isPostEditable: Boolean
  },
  data() {
    return {
      courseId: "",
      topicId: "",
      title: "",
      body: "",
      bodyHTML: "",
      briefDescription: "",
      newImage: "",
      altText: [],
      errorMessage: "",
      isAnonymous: false,
      isDisabled: true,
      isLoading: false,
      isPostLoading: false,
      isDraftLoading: false,
      dropdownValue: this.$t("discussions.post.select_subcategory"),
      isDropdownOpen: false,
      isDropdownHovered: false,
      showError: false,
      dropdownErrorMessage: "",
      optionVisible: false,
      isDescriptionBlockVisible: false,
      isImageBlockVisible: false,
      chevronDefault: require("@/assets/images/vle/chevron-blue.svg"),
      chevronHover: require("@/assets/images/vle/chevron-lightblue.svg"),
      chevronSelected: require("@/assets/images/vle/chevron-white.svg"),
      anonymousImage: require("@/assets/images/vle/anonymous.svg"),
      defaultImage: require("@/assets/images/menu/avatar.svg"),
      addBlockIcon: require("@/assets/images/vle/plus-icon.svg"),
      addImage: require("@/assets/images/vle/add-image.svg"),
      removeImage: require("@/assets/images/vle/close-icon.svg"),
      successImage: require("@/assets/images/vle/checkmark-green.svg"),
      itemSelected: require("@/assets/images/vle/checkmark-blue.svg"),
      addText: require("@/assets/images/vle/add-text.svg"),
      imageUpload: require("@/assets/images/vle/forums/image-upload.svg"),
      imageError: require("@/assets/images/vle/warning.svg"),
      checkboxChecked: require("@/assets/images/vle/checkbox-checked-blue.svg"),
      showSuccessCheck: true,
      isLoaded: false,
      currentField: "",
      textCounts: {
        title: 0,
        body: 0,
        briefDescription: 0
      },
      textLimits: {
        title: 100,
        body: 1500,
        briefDescription: 1500
      },
      showCounter: false,
      hideCounter: false,
      DISCUSSION_FORUM_MODAL_IDS: {},
      submitType: "",
      tryAgain: false,
      content_list: [],
      newBlockArray: [],
      newBlockIndexEditing: -1,
      lastFailedIndex: null,
      uniqueIdCounter: 0,
      isTitleToxic: false,
      isBodyToxic: false
    };
  },
  async created() {
    this.isPostLoading = true;
    this.DISCUSSION_FORUM_MODAL_IDS = DISCUSSION_FORUM_MODAL_IDS;

    const path = this.$route.path;
    const isDraftPost = path.includes("draft");
    const isEditableForm = this.isPostEditable || isDraftPost;
    if (!isEditableForm) {
      this.topicId = this.getTopicId;
      this.dropdownValue = !this.topicId
        ? this.$t("discussions.post.select_subcategory")
        : this.getTopicName?.subCategoryName ?? this.getTopicName?.categoryName;
      this.isPostLoading = false;
      return;
    } else {
      const postId = this.$route.params.postId;
      const action = this.$route.path.includes("draft")
        ? "getDraftPostDetails"
        : "getPostDetails";
      await this.$store.dispatch(action, {
        postId
      });
    }

    await this.$store.dispatch("getDiscussionTopics", this.$route.params.id);

    this.topicId = this.getSelectedDiscussionPost.commentable_id;
    this.findSelectedTopicName(this.showDiscussionTopics, this.topicId);
    this.preFilledValues();
    this.isPostLoading = false;
  },
  computed: {
    ...mapGetters([
      "showDiscussionTopics",
      "getTopicId",
      "language",
      "getAuthProfile",
      "allConfig",
      "getSelectedDiscussionPost",
      "getTopicName",
      "getDiscussionPosts"
    ]),
    isTextLimitExceeded() {
      const titleExceeded = this.textCounts["title"] > this.textLimits["title"];
      const bodyExceeded = this.textCounts["body"] > this.textLimits["body"];
      const briefDescriptionExceeded =
        this.textCounts["briefDescription"] >
        this.textLimits["briefDescription"];
      return titleExceeded || bodyExceeded || briefDescriptionExceeded;
    },
    disableUpdateBtn() {
      return (
        !this.title ||
        !this.body ||
        this.isTextLimitExceeded ||
        this.newBlockCounter > this.textLimits[this.currentField]
      );
    },
    newBlockCounter() {
      let updatedCount = this.newBlockArray.find(
        (count, index) => index === this.newBlockIndexEditing
      );
      return updatedCount?.briefDescription?.length;
    },
    hasProfileImage() {
      return (
        !this.isAnonymous && this.getAuthProfile.profile_image_url !== null
      );
    },
    userImageUrl() {
      return this.isAnonymous
        ? this.anonymousImage
        : this.hasProfileImage
        ? this.getAuthProfile.profile_image_url
        : this.defaultImage;
    },
    displayName() {
      return this.isAnonymous
        ? this.$t("discussions.anonymous")
        : this.$t("dashboard.welcome_back.user_name", {
            s: this.getDiscussionPosts?.user_info?.username
              ? this.getDiscussionPosts?.user_info?.username
              : this.getAuthProfile?.name
          });
    }
  },
  methods: {
    setSubmitType(type) {
      this.submitType = type;
    },
    async updateCourseDraftPost({ actionType }) {
      this.isDraftLoading = true;
      const formData = new FormData();
      let formFields;
      formFields = {
        course_id: this.$route.params.id,
        topic_id: this.topicId ? this.topicId : "",
        title: this.title ? this.title : "",
        body: this.bodyHTML
          ? this.bodyHTML
          : this.getSelectedDiscussionPost.is_draft
          ? this.getSelectedDiscussionPost.body
          : "",
        anonymous: this.isAnonymous,
        content_list: JSON.stringify(
          this.newBlockArray
            .map((item, index) => {
              if (item.type === "image" && item.newImage) {
                return {
                  image: item.newImage,
                  sequence: index + 1,
                  alt_tag: item.alt_tag
                };
              } else if (item.type === "text" && item.briefDescription) {
                return {
                  brief_description: item.briefDescriptionHTML,
                  sequence: index + 1
                };
              }
              return null;
            })
            .filter(item => item !== null)
        ),
        is_draft: true
      };
      for (const [key, value] of Object.entries(formFields)) {
        formData.append(key, value);
      }
      this.$store
        .dispatch("updateCourseDiscussionPost", {
          postId: this.getSelectedDiscussionPost.id,
          postDetails: formData,
          isDraft: true
        })
        .then(() => {
          this.resetPostEditable();
          this.$bvToast.show("draft-toast");
          this.isDraftLoading = false;
          this.$store.commit("SET_ALL_POSTS_ACTIVE");
          this.discussionViewSelector({ actionType });
          this.$store.dispatch("getDiscussionTopics", this.$route.params.id);
        })
        .catch(() => {
          this.isDraftLoading = false;
        });
    },
    async submitForm(postAs) {
      if (postAs === "draft-post") {
        this.isDraftLoading = true;
      } else {
        this.isLoading = true;
      }
      const formData = new FormData();
      let formFields;
      if (postAs === "draft-post") {
        formFields = {
          course_id: this.$route.params.id,
          topic_id: this.topicId ? this.topicId : "",
          title: this.title ? this.title : "",
          body: this.bodyHTML
            ? this.bodyHTML
            : this.getSelectedDiscussionPost.is_draft
            ? this.getSelectedDiscussionPost.body
            : "",
          anonymous: this.isAnonymous,
          content_list: JSON.stringify(
            this.newBlockArray
              .map((item, index) => {
                if (item.type === "image" && item.newImage) {
                  return {
                    image: item.newImage,
                    sequence: index + 1,
                    alt_tag: item.alt_tag
                  };
                } else if (item.type === "text" && item.briefDescription) {
                  return {
                    brief_description: item.briefDescriptionHTML,
                    sequence: index + 1
                  };
                }
                return null;
              })
              .filter(item => item !== null)
          ),
          is_draft: true
        };
      } else if (this.submitType === "update") {
        formFields = {
          topic_id: this.topicId,
          title: this.title,
          body: this.bodyHTML
            ? this.bodyHTML
            : this.getSelectedDiscussionPost.body,
          anonymous: this.isAnonymous,
          content_list: JSON.stringify(
            this.newBlockArray
              .map((item, index) => {
                if (item.type === "image" && item.newImage) {
                  return {
                    image: item.newImage,
                    sequence: index.id,
                    alt_tag: item.alt_tag
                  };
                } else if (item.type === "text" && item.briefDescription) {
                  return {
                    brief_description: item.isUpdatingContent
                      ? item.briefDescriptionHTML
                      : item.briefDescription,
                    sequence: index.id
                  };
                }
                return null;
              })
              .filter(item => item !== null)
          )
        };
      } else {
        formFields = {
          course_id: this.$route.params.id,
          topic_id: this.topicId,
          title: this.title,
          body: this.bodyHTML
            ? this.bodyHTML
            : this.getSelectedDiscussionPost.is_draft
            ? this.getSelectedDiscussionPost.body
            : "",
          anonymous: this.isAnonymous,
          content_list: JSON.stringify(
            this.newBlockArray
              .map(item => {
                if (item.type === "image" && item.newImage) {
                  return {
                    image: item.newImage,
                    sequence: item.id,
                    alt_tag: item.alt_tag
                  };
                } else if (item.type === "text" && item.briefDescription) {
                  return {
                    brief_description: item.briefDescriptionHTML,
                    sequence: item.id
                  };
                }
                return null;
              })
              .filter(item => item !== null)
          )
        };
        if (this.getSelectedDiscussionPost.is_draft) {
          formFields.is_publish = true;
          formFields.id = this.getSelectedDiscussionPost.id;
        }
      }

      for (const [key, value] of Object.entries(formFields)) {
        formData.append(key, value);
      }

      if (this.submitType === "update") {
        this.$store
          .dispatch("updateCourseDiscussionPost", {
            postId: this.getSelectedDiscussionPost.id,
            postDetails: formData
          })
          .then(() => {
            this.resetPostEditable();
            this.$bvToast.show("update-toast");
            this.isLoading = false;
            this.$store.commit("SET_ALL_POSTS_ACTIVE");
            this.discussionViewSelector();
            this.$store.dispatch("getDiscussionTopics", this.$route.params.id);
          })
          .catch(() => {
            this.isLoading = false;
            this.tryAgain = true;
            this.$bvToast.show("toxic-language-toast");
          });
      } else {
        this.$store
          .dispatch("createCourseDiscussionPost", formData)
          .then(response => {
            if (postAs === "draft-post" && response && response.is_draft) {
              this.$bvToast.show("draft-toast");
              this.isDraftLoading = false;
            } else {
              this.$bvToast.show("publish-toast");
              this.isLoading = false;
            }
            // update the topic id
            this.$store.commit("SET_ALL_POSTS_ACTIVE");
            this.discussionViewSelector({ postAs });
            this.$store.dispatch("getDiscussionTopics", this.$route.params.id);
          })
          .catch(error => {
            if (postAs === "draft-post") {
              this.isDraftLoading = false;
            } else {
              this.isTitleToxic = error.response.data.error.title.toxic;
              this.isBodyToxic = error.response.data.error.body.toxic;
              this.isLoading = false;
              this.tryAgain = true;
              this.$bvToast.show("toxic-language-toast");
            }
          });
      }
    },
    draftFormIsUntouched() {
      const old_title = this.getSelectedDiscussionPost.title;
      const old_body = this.getSelectedDiscussionPost.body;
      const old_topicId = this.getSelectedDiscussionPost.commentable_id;

      return (
        this.title === old_title &&
        this.body === old_body &&
        this.topicId === old_topicId &&
        this.arraysEqual(
          this.newBlockArray,
          this.getSelectedDiscussionPost.content_list
        )
      );
    },
    arraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }

      for (let i = 0; i < arr1.length; i++) {
        if (
          arr1[i].newImage !== arr2[i].image ||
          arr1[i].briefDescription !== arr2[i].brief_description ||
          arr1[i].alt_tag !== arr2[i].alt_tag
        ) {
          return false;
        }
      }

      return true;
    },
    handlePostActions(actionType) {
      const mandatoryFields = this.topicId && this.title && this.body;
      const isDraftPost =
        mandatoryFields &&
        this.getSelectedDiscussionPost.is_draft &&
        this.getSelectedDiscussionPost.id;

      if (isDraftPost) {
        if (actionType === "goBack") {
          if (this.draftFormIsUntouched()) {
            this.discussionViewSelector({ actionType });
            return;
          }
          this.$bvModal.show(this.DISCUSSION_FORUM_MODAL_IDS.SAVE_DRAFT_MODAL);
        } else {
          this.$bvModal.hide(this.DISCUSSION_FORUM_MODAL_IDS.SAVE_DRAFT_MODAL);
          this.updateCourseDraftPost({ actionType });
        }
      } else if (mandatoryFields && !this.isPostEditable) {
        if (actionType === "goBack") {
          this.$bvModal.show(this.DISCUSSION_FORUM_MODAL_IDS.SAVE_DRAFT_MODAL);
        } else {
          this.$bvModal.hide(this.DISCUSSION_FORUM_MODAL_IDS.SAVE_DRAFT_MODAL);
          this.submitForm("draft-post");
        }
      } else {
        this.resetPostEditable();
        if (actionType === "goBack") {
          this.discussionViewSelector({ actionType });
        }
      }
    },
    resetPostEditable() {
      if (this.isPostEditable) {
        this.$emit("from-view-post", false);
      }
    },
    discussionViewSelector({ postAs, actionType } = {}) {
      this.$store.commit(
        "SET_DISCUSSION_VIEW_SELECTOR",
        this.DISCUSSION_VIEW_SELECTOR.PostsList
      );
      const basePath = `/self-paced/${this.$route.params.id}/forums`;
      const topicPath = `${basePath}/${this.topicId}/view`;
      const topicURLPath = `${basePath}/${this.$route.params.topic_id}/view`;
      let routePath;
      if (postAs === "draft-post") {
        routePath = basePath;
      } else if (this.submitType === "update" && this.topicId) {
        routePath = topicPath;
      } else if (this.$route.params.topic_id) {
        routePath = topicURLPath;
      } else if (this.topicId) {
        routePath = actionType ? basePath : topicPath;
      } else {
        routePath = basePath;
      }
      this.$router.push({ path: routePath });
    },
    selectDropdownItem(item) {
      this.topicId = item.id;
      this.dropdownValue = item.name;
      this.isDropdownOpen = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      if (this.isDropdownOpen) {
        document.addEventListener("click", this.outsideClickHandler);
      } else {
        document.removeEventListener("click", this.outsideClickHandler);
      }
    },
    outsideClickHandler(event) {
      const dropdown = document.getElementById("select-category");
      if (!dropdown.contains(event.target)) {
        this.isDropdownOpen = false;
        document.removeEventListener("click", this.outsideClickHandler);
        if (!this.topicId) {
          this.showError = true;
          this.dropdownErrorMessage = `${this.$t(
            "discussions.post.select_subcategory"
          )} ${this.$t("discussions.post.field_required")}`;
        } else {
          this.showError = false;
          this.dropdownErrorMessage = "";
        }
      }
    },
    toggleAddBlock() {
      this.optionVisible = !this.optionVisible;
    },
    showDescriptionBlock() {
      let obj = {
        type: "text",
        briefDescription: "",
        id: this.generateUniqueId()
      };
      this.newBlockArray = [...this.newBlockArray, obj];
      this.optionVisible = false;
    },
    showImageBlock() {
      this.optionVisible = false;
      document.getElementById("post-image").click();
    },
    generateUniqueId() {
      this.uniqueIdCounter += 1;
      return this.uniqueIdCounter;
    },
    removeImageBlock(index, id) {
      const _newBlockArray = this.newBlockArray.filter(
        block => block.id !== id
      );
      this.newBlockArray = _newBlockArray;

      if (index + 1 === this.lastFailedIndex) {
        this.lastFailedIndex = index;
      }
    },
    onUploadChange(e) {
      let img = e.target.files[0];
      let imgData = new FormData();
      imgData.append("image", img);

      if (img) {
        let index;
        const newBlockArrayItem = {
          type: "image",
          isLoaded: false,
          errorMessage: "",
          showSuccessCheck: true,
          alt_tag: ""
        };
        if (this.lastFailedIndex !== null) {
          const _newBlockArray = this.newBlockArray.slice();
          _newBlockArray.splice(this.lastFailedIndex, 1, newBlockArrayItem);
          this.newBlockArray = _newBlockArray;
          index = this.lastFailedIndex;
        } else {
          index = this.newBlockArray.length;
          this.newBlockArray.push(newBlockArrayItem);
        }
        this.isImageBlockVisible = true;
        const validationStatus = this.imageUploadValidation(img);
        if (validationStatus.isValid) {
          axios
            .post(
              `${this.allConfig.baseURL}/discussions/upload-image/`,
              imgData,
              {
                headers: {
                  Authorization: `Bearer ${this.$keycloak.token}`
                }
              }
            )
            .then(res => {
              e.target.value = null;
              let obj = {
                type: "image",
                newImage: res.data.image,
                id: this.generateUniqueId(),
                alt_tag: newBlockArrayItem.alt_tag
              };
              const _newBlockArray = this.newBlockArray.slice();
              _newBlockArray.splice(index, 1, obj);
              this.newBlockArray = _newBlockArray;
              if (this.lastFailedIndex !== null) {
                this.lastFailedIndex = null;
              }
            })
            .catch(err => {
              this.$store.commit("SET_ERRORS", err.response.data);
            });
        } else {
          let obj = {
            type: "image",
            errorMessage: validationStatus.errorMessage
          };
          this.newBlockArray[index] = obj;
          this.lastFailedIndex = index;
        }
      }
    },
    loadImage(index) {
      let obj = {
        ...this.newBlockArray[index],
        isLoaded: true,
        showSuccessCheck: true
      };
      const _newBlockArray = this.newBlockArray.slice();
      _newBlockArray.splice(index, 1, obj);
      this.newBlockArray = _newBlockArray;
      if (this.newBlockArray[index].newImage) {
        setTimeout(() => {
          let obj = { ...this.newBlockArray[index], showSuccessCheck: false };
          const _newBlockArray = this.newBlockArray.slice();
          _newBlockArray.splice(index, 1, obj);
          this.newBlockArray = _newBlockArray;
        }, 2000);
      }
    },
    updateTextCount(field) {
      this.currentField = field;
      this.textCounts[this.currentField] = this[this.currentField].length;
      if (this.textCounts[this.currentField] > 0) {
        this.showCounter = true;
      }
    },
    handleFocus(field, index) {
      this.currentField = field;
      if (this.textCounts[this.currentField] > 0) {
        this.showCounter = true;
      }
      if (this.currentField === "briefDescription") {
        this.newBlockIndexEditing = index;
        this.hideCounter = false;
      }
    },
    handleBlur(field) {
      this.currentField = field;
      if (this.title) {
        this.checkToxicLanguage(field);
      } else {
        this.isTitleToxic = false;
      }
      if (
        this.textCounts[this.currentField] > this.textLimits[this.currentField]
      ) {
        this.showCounter = true;
        if (this.currentField === "title") {
          this.$refs[this.currentField + "Input"].focus();
        } else {
          this.$refs[this.currentField + "Input"].$refs.textFormatter.focus();
        }
      } else {
        this.showCounter = false;
      }
    },
    async checkToxicLanguage(field, idx, id) {
      let toxicText = "";
      const contentItem = this.newBlockArray.find(item => item.id === id);
      if (field === "title") {
        toxicText = this.title;
      } else if (field === "body") {
        toxicText = this.body;
      } else {
        toxicText = contentItem.briefDescription;
      }
      const response = await this.$store.dispatch("checkToxicLanguage", {
        text: toxicText,
        language: ""
      });
      let isToxic = Math.floor(response.toxicity.score * 10) / 10 > 0.5;
      if (field === "title") {
        this.isTitleToxic = isToxic;
      } else if (field === "body") {
        this.isBodyToxic = isToxic;
      } else {
        contentItem.isToxic = isToxic;
        const _newBlockArray = this.newBlockArray.slice();
        _newBlockArray.splice(idx, 1, contentItem);
        this.newBlockArray = _newBlockArray;
      }
      if (isToxic) {
        this.$bvToast.show("toxic-language-toast");
      }
      if (
        this.tryAgain &&
        !this.isTitleToxic &&
        !this.isBodyToxic &&
        !this.newBlockArray.some(item => item.isToxic)
      ) {
        this.tryAgain = false;
      }
    },
    inputHTML(field, eventData, index, isUpdatingContent, id) {
      if (field === "body") {
        this.bodyHTML = eventData;
      } else if (field === "briefDescription") {
        let obj = {
          ...this.newBlockArray[index],
          briefDescriptionHTML: eventData
        };
        const _newBlockArray = this.newBlockArray.slice();
        _newBlockArray.splice(index, 1, obj);
        this.newBlockArray = _newBlockArray;
      }
      //toxic language
      this.currentField = field;
      if (field === "body") {
        if (eventData) {
          this.checkToxicLanguage(field);
        } else {
          this.isBodyToxic = false;
        }
      } else if (field === "briefDescription") {
        let block = this.newBlockArray.find(item => item.id === id);
        const fieldValue = block[field];
        if (fieldValue && fieldValue.trim() !== "") {
          this.checkToxicLanguage(field, index, block.id);
        } else {
          let obj = { ...this.newBlockArray[index], isToxic: false };
          const _newBlockArray = this.newBlockArray.slice();
          _newBlockArray.splice(index, 1, obj);
          this.newBlockArray = _newBlockArray;
        }
      }
      if (this.currentField === "briefDescription") {
        let updatedCount = this.newBlockArray.find(
          (count, idx) => idx === index
        );
        if (updatedCount?.briefDescription?.length > 1500) {
          this.$refs[
            "briefDescriptionInput-" + index
          ][0].$refs.textFormatter.focus();
        } else {
          this.hideCounter = true;
        }

        if (!isUpdatingContent) {
          let obj = {
            ...this.newBlockArray[index],
            isUpdatingContent: true
          };
          const _newBlockArray = this.newBlockArray.slice();
          _newBlockArray.splice(index, 1, obj);
          this.newBlockArray = _newBlockArray;
        }
      }

      if (
        (this.isPostEditable || this.getSelectedDiscussionPost.is_draft) &&
        this.currentField === "briefDescription"
      ) {
        this.isUpdatingContent = true;
      }
    },
    findSelectedTopicName(topics, topicId) {
      for (const topic of topics) {
        if (topic.id === topicId) {
          this.dropdownValue = topic.name;
          return;
        }
        if (topic.categories && topic.categories.length > 0) {
          this.findSelectedTopicName(topic.categories, topicId);
        }
      }
    },
    getContentById(id) {
      const contentItem = this.newBlockArray.find(item => item.id === id);
      return (this.getSelectedDiscussionPost.is_draft || this.isPostEditable) &&
        contentItem
        ? contentItem?.initialBriefDescription
        : "";
    },
    showDeletePostModal() {
      this.$bvModal.show(this.DISCUSSION_FORUM_MODAL_IDS.DELETE_POST_MODAL);
    },
    deletePost() {
      this.$store
        .dispatch("deleteCourseDiscussionPost", {
          postId: this.getSelectedDiscussionPost.id,
          isDraft: this.getSelectedDiscussionPost.is_draft
        })
        .then(() => {
          this.resetPostEditable();
          this.$store.commit("SET_ALL_POSTS_ACTIVE");
          this.$store.commit(
            "SET_DISCUSSION_VIEW_SELECTOR",
            this.DISCUSSION_VIEW_SELECTOR.PostsList
          );
          this.$bvToast.show("delete-toast");
          this.$store.dispatch("getDiscussionTopics", this.$route.params.id);
          const basePath = `/self-paced/${this.$route.params.id}/forums`;
          const routePath = this.getSelectedDiscussionPost.is_draft
            ? basePath
            : `${basePath}/${this.topicId}/view`;
          this.$router.push({ path: routePath });
        });
    },
    preFilledValues() {
      this.title = this.getSelectedDiscussionPost.title;
      this.body = this.getSelectedDiscussionPost.body;
      this.newBlockArray = this.getSelectedDiscussionPost.content_list.map(
        item => {
          if (item.image) {
            return {
              type: "image",
              newImage: item.image,
              showSuccessCheck: false,
              id: this.generateUniqueId(),
              alt_tag: item.alt_tag
            };
          } else {
            return {
              type: "text",
              briefDescription: item.brief_description,
              briefDescriptionHTML: item.brief_description,
              id: this.generateUniqueId(),
              isUpdatingContent: false,
              initialBriefDescription: item.brief_description
            };
          }
        }
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.add__post--wrapper {
  @include flex-horizontal-center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $brand-primary-50;
  z-index: 3;

  .body-head {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #25282b;
  }

  .body-text {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.2px;
  }

  .add-post__loader {
    margin-top: 30px;
  }
}

.add__post--inner,
.add__post--inner > *,
.add__post--inner > * > form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.add__post--header {
  background-color: $brand-neutral-0;
  padding: 8px 16px;
  box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.2);
  @include horizontal-space-between;

  .form-check {
    margin-right: 24px;
    margin-bottom: 0;
    padding: 0;
    .body-text {
      padding-left: 26px;
      font-size: 14px;
      color: $brand-primary;
      position: relative;
    }
    .form__check--input {
      margin: 0;
      margin-top: 1px;
      border-color: $brand-primary;
      border-width: 2px;
      border-radius: 3px;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 16px;
      height: 16px;

      &:hover:not(:checked) {
        box-shadow: 0 0 0 0.25rem $brand-primary-100;
        background-color: $brand-primary-100;
        border-color: $brand-primary-400;
      }

      &:focus {
        box-shadow: none;
      }

      &:checked {
        box-shadow: none;
        background-color: $brand-primary;
        background-size: 16px;

        &:hover {
          border-color: $brand-primary;
        }
      }
    }
    .checkmark {
      display: none;
    }
    &.active {
      .form__check--input {
        opacity: 0;
      }
      .checkmark {
        display: block;
        position: absolute;
        left: 0px;
        top: 0px;
      }
    }
  }
  .back-btn {
    display: flex;
    align-items: center;
    .back-image {
      margin-right: 8px;
    }
  }
  .back__btn--text {
    @include button-label;
    color: $brand-primary;
  }

  .delete-btn {
    margin-left: 24px;
  }

  .body-text {
    margin: 0;
  }

  .action-panel {
    display: flex;

    .lxp-button {
      padding: 8px 24px;
      min-width: 115px;
    }

    .try-again:not(.loading-btn) {
      background-color: $brand-warning !important;

      &:hover {
        background-color: $brand-warning-600 !important;
      }
    }
  }
}

.addpost-content {
  padding: 28px 24px 0px;
  display: flex;
  flex-direction: column;
  min-height: 0;

  .scrollbar-wrapper {
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
      border-radius: 8px;
      border: none;
      background-color: $brand-neutral-75;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: none;
      background-color: $brand-neutral-300;
    }

    .addpost__content--inner {
      width: 608px;
      margin: 0px auto 28px;
      border-radius: 16px;
      background-color: $brand-neutral-0;
      padding: 16px 24px 30px;

      .form-group {
        margin-bottom: 24px;
      }
    }
  }
}

.addpost__content--header {
  @include horizontal-space-between;
  flex-wrap: wrap;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid $brand-neutral-200;
  position: relative;
  z-index: 3;
}

.error {
  margin-top: 10px;
  display: block;
  color: $brand-error-400;
  font-size: 12px;
  width: 100%;
}

.select-category {
  max-width: 50%;
  position: relative;
  z-index: 1;

  .selector {
    padding: 6px 28px 6px 8px;
    border-radius: 8px;
    background-color: $brand-primary-100;
    cursor: pointer;
    position: relative;

    .label {
      @include label-large($brand-primary-700, 500);
      @include text-ellipsis;
    }

    img {
      position: absolute;
      top: 8px;
      right: 4px;
      width: 18px;
    }

    &:hover {
      .label {
        color: $brand-primary-400;
      }
    }

    &.selector-active {
      background-color: $brand-primary;

      .label {
        color: $brand-primary-50;
      }
    }
  }

  .select-item {
    width: 248px;
    padding: 0px 4px 0px 0px;
    border-radius: 8px;
    background-color: $brand-neutral-0;
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    z-index: 1;
    box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    overflow: hidden;

    .select__item--inner {
      padding: 0px 4px 0px 0px;
      margin: 0;
      list-style-type: none;
      overflow: hidden;
      max-height: 260px;
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: $brand-neutral-75;
      }

      &::-webkit-scrollbar {
        width: 4px;
        height: calc(100% - 16px);
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: $brand-neutral-300;
      }
    }

    .category-list {
      border-top: 1px solid $brand-neutral-200;

      ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
      }
    }

    .parent-category {
      @include label-medium(500);
      color: $brand-neutral-300;
      padding: 8px 8px 2px;
      cursor: default;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .clickable-category {
      padding: 8px;
      @include body-medium;
      color: $brand-neutral-700;
      cursor: pointer;
      @include horizontal-space-between;

      &:hover,
      &.current {
        background-color: $brand-primary-100;
      }
    }
  }
}

.form__control--edit {
  border: none;
  padding: 0;

  &.input-edit {
    @include h5($brand-neutral-700);
    height: 35px;
  }

  &:focus {
    box-shadow: none;
  }
}

.post-user {
  display: flex;
  align-items: center;
  max-width: 50%;

  .user-image {
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    @include flex-horizontal-center;

    img {
      width: 100%;
    }

    &.user-bg {
      background-image: url("~@/assets/images/vle/avatar-frame.svg");
      background-size: 100%;
      background-position: center;
      padding: 6px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }

  span {
    margin-left: 8px;
    @include label-large($brand-neutral-700, 500);
  }
}

.add-block {
  display: flex;

  .icon-style {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $brand-primary-100;
    @include flex-horizontal-center;

    img {
      transition: all ease 0.3s;
    }
  }

  .add-icon {
    position: relative;
    z-index: 2;

    &:hover {
      box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    }
  }

  .block-options {
    list-style-type: none;
    display: flex;
    margin: 0 0 0 12px;
    padding: 0;
    position: relative;
    z-index: 1;

    li {
      background-color: $brand-neutral-0;
      border: 1px solid $brand-primary;
      opacity: 0;
      transition: all ease 0.3s;

      &:hover {
        background-color: $brand-primary-100;
      }

      &:not(:first-child) {
        margin-left: 12px;
      }

      &:first-child {
        transform: translateX(-60px);
      }

      &:last-child {
        transform: translateX(-120px);
      }
    }

    &.active {
      li {
        opacity: 1;
        transform: translateX(0px);
      }
    }
  }
}

.image-loading {
  border: 2px solid $brand-neutral-200;
  position: relative;
}

.image__block--container {
  margin-bottom: 24px;
  display: block;
}

.image-block {
  width: 100%;
  height: 222px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &.uploaded-image {
    padding-bottom: 30px;
    height: auto;
    background-color: $brand-neutral-0;
  }

  .image-alt {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 30px;
    padding: 4px 8px;
    color: $brand-neutral-700;
    background-color: $brand-primary-50;
    border: none;
    @include body-medium;

    &:focus {
      border: none;
      outline: none;
    }
  }

  .remove-image {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    right: 8px;
    @include flex-horizontal-center;
    background-color: $brand-neutral-0;
    cursor: pointer;

    img {
      object-fit: fill;
    }
  }
}

.image-error {
  border: 2px solid $brand-warning;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: flex-start;

  .image__error--text {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
  }

  span:not(.image-retry) {
    @include body-medium;
    color: $brand-neutral-900;
  }

  .image-retry {
    @include button-label;
    color: $brand-warning;
    margin-top: 12px;
    margin-left: 8px;
    cursor: pointer;
  }
}

.counter {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  @include label-medium;
  color: $brand-neutral-300;

  &.counter-error {
    color: $brand-error-400;
  }

  .counter-number {
    margin-left: auto;
  }
}
.toxic-input {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: -8px;
    width: 2px;
    height: 100%;
    background-color: $brand-error-400;
  }
}

[dir="rtl"] {
  .add__post--header {
    .form-check {
      margin-right: 0px;
      margin-left: 24px;
    }
    .back-btn {
      .back-image {
        margin-right: 0px;
        margin-left: 8px;
      }
    }
  }
  .add__post--wrapper {
    text-align: right;
  }
  .post-user {
    span {
      margin-left: 0px;
      margin-right: 8px;
    }
  }
  .add-block {
    .block-options {
      margin-left: 0px;
      margin-right: 12px;
      li {
        &:not(:first-child) {
          margin-left: 0px;
          margin-right: 12px;
        }
      }
    }
  }
  .image-error {
    .image__error--text {
      margin-left: 0px;
      margin-right: 16px;
    }
  }
}
</style>
